// close icon on input
.ant-input-clear-icon {
  > span.anticon {
    transform: translateY(-3px) !important;
  }
}

// alway show clear icon on select component
.ant-select .ant-select-clear {
  opacity: 1 !important;
}

.ant-picker.ant-picker-range {
  .ant-picker-range-separator {
    transform: translate(-8px, -4px);
  }
}

.ant-btn.icon-tf-2 {
  svg {
    transform: translateX(2px);
  }
}
.ant-btn.icon-tf-3 {
  svg {
    transform: translateX(3px);
  }
}
