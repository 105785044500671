.preview-slip {
  .ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 450px;
    }
  }
}
