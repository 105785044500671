:root {
  --primary-color: #32c790;
  --primary-color-dark: #09a76d;
  --primary-color-light: #b3ffe3;
  --red-pastel-color: #ff5454;
}

.c-table {
  // .ant-table-thead {
  //   th {
  //     font-weight: bold;
  //   }
  // }

  &.header-center {
    .ant-table-thead {
      th {
        text-align: center;
      }
    }
  }
}

.label-primary {
  color: var(--primary-color);
}
.label-red-pastel {
  color: var(--red-pastel-color);
}

.no-margin {
  margin: 0px !important;
}
