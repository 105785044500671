@import 'styles';

// $sidebar-bg-color: #1d1d1d !default;
// $sidebar-color: #adadad !default;
// $sidebar-width: 200px;
// $sidebar-collapsed-width: 80px !default;
// $highlight-color: #d8d8d8 !default;
// $submenu-bg-color: #2b2b2b !default;
// $submenu-bg-color-collapsed: #2b2b2b !default;
// $icon-bg-color: #2b2b2b !default;
// $icon-size: 35px !default;
// $submenu-indent: 24px !default;
// $breakpoint-xs: 480px !default;
// $breakpoint-sm: 576px !default;
// $breakpoint-md: 768px !default;
// $breakpoint-lg: 992px !default;
// $breakpoint-xl: 1200px !default;
// $breakpoint-xxl: 1600px !default;

// @import '~react-pro-sidebar/dist/scss/styles.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
